import { Plan } from '@/api/models/model_product_detail'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { PlanConfig } from '../CustomerSubscription'

interface SearchableSelectProps {
    plans: Plan[]
    selectedPlans: PlanConfig[]
    onSelectPlan: (plan: Plan | null) => void
}

export default function SearchableSelect({ plans, selectedPlans, onSelectPlan }: SearchableSelectProps) {
    return (
        <Select
            onValueChange={(selectedOption) => {
                const selectedPlan = plans?.find((plan) => plan.name === selectedOption)
                onSelectPlan(selectedPlan!)
            }}
        >
            <SelectTrigger className="w-full">
                <SelectValue placeholder="Select Add-on" />
            </SelectTrigger>
            <SelectContent>
                {plans
                    ?.filter((value) => !selectedPlans.some((selectedPlan) => selectedPlan.name === value.name))
                    .map((value, index) => (
                        <SelectItem key={index} value={value.name}>
                            {value.name}
                        </SelectItem>
                    ))}
            </SelectContent>
        </Select>
    )
}
