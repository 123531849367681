import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import DatePicker, { DayValue } from '@amir04lm26/react-modern-calendar-date-picker'
import FlexColors from '@/utils/colors'

interface DatePickerProps {
    label: string
    selectedDate: DayValue | null
    onDatePicked: (date: DayValue) => void
}

export default function FlexDatePicker({ selectedDate, onDatePicked, label }: DatePickerProps) {
    const renderCustomInput = ({ ref }: { ref: any }) => (
        <input
            readOnly
            ref={ref}
            placeholder="Select date"
            value={selectedDate ? `${selectedDate.day}/${selectedDate.month}/${selectedDate.year}` : ''}
            className="px-2 border-orange-600 border rounded-lg items-center text-center py-1 w-28"
        />
    )

    return (
        <div className="flex items-center gap-5">
            <label className="text-md font-semibold text-gray-600">{label}</label>
            <DatePicker
                value={selectedDate}
                onChange={onDatePicked}
                inputPlaceholder="Select a date"
                colorPrimary={FlexColors.primary}
                renderInput={renderCustomInput}
                inputClassName="border border-orange-600 rounded-md px-4 py-3 text-xl focus:outline-none focus:border-orange-500 transition-all"
            />
        </div>
    )
}
