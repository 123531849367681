import { GrDocument } from 'react-icons/gr'
import { PlanConfig } from '../CustomerSubscription'
import { formatNumberUS } from '@/utils/utility_functions'
import Spacer from '@/components/common/util/spacer'

interface SubscriptionPreviewProps {
    productName?: string
    planName?: string
    fixedDiscount: number // Now in percentage (0 to 100)
    recurringDiscount: number // Now in percentage (0 to 100)
    planConfig: PlanConfig[]
    addonConfig: PlanConfig[]
}

export default function SubscriptionPreview({
    planConfig,
    addonConfig,
    productName,
    planName,
    fixedDiscount,
    recurringDiscount,
}: SubscriptionPreviewProps) {
    // Calculate the total price for all items
    let totalPrice = planConfig.reduce((total, plan) => total + plan.quantity * plan.price, 0)

    // Calculate recurring price (only for MONTHLY plans)
    const recurringPrice = planConfig.reduce((total, plan) => {
        if (plan.billingPeriod === 'MONTHLY') {
            return total + plan.quantity * plan.price
        }
        return total
    }, 0)

    // Add add-ons price to the total
    const recurringPriceAddon = addonConfig.reduce((total, addon) => total + addon.quantity * addon.price, 0)
    totalPrice += recurringPriceAddon

    // Apply fixed discount percentage to total price
    const discountedTotalPrice = totalPrice - (totalPrice * fixedDiscount) / 100

    // Apply recurring discount percentage to recurring price
    const discountedRecurringPrice = recurringPrice - (recurringPrice * recurringDiscount) / 100

    return (
        <div>
            <div className="px-5 py-5 border rounded-lg border-gray-600 fill-background bg-gray-200">
                <div className="flex text-xl gap-5 w-full content-center">
                    <GrDocument className="h-6 w-6" />
                    <span className="text-xl font-bold">Subscription Preview</span>
                </div>
                <Spacer height={10} />
                <span className="text-sm font-semibold">Recurring Charges</span>
                <div className="flex gap-1 mt-3 text-sm">
                    <span>{productName}/</span>
                    <span>{planName}</span>
                </div>

                {planConfig.map((plan) =>
                    plan.quantity > 0 ? (
                        <div key={plan.name} className="mt-2 flex text-sm justify-between">
                            <div className="text-gray-800">{plan.name}</div>

                            <div className="text-gray-700">
                                {plan.quantity > 1 ? (
                                    <div className="flex items-center">
                                        <span>
                                            {plan.quantity} x ${formatNumberUS(plan.price)}
                                        </span>{' '}
                                        =<span className="ml-2">${formatNumberUS(plan.quantity * plan.price)}</span>
                                    </div>
                                ) : (
                                    <div>${formatNumberUS(plan.price)}</div>
                                )}
                            </div>
                        </div>
                    ) : null
                )}

                <Spacer height={10} />
                {addonConfig.length > 0 && (
                    <div className="flex items-center">
                        <span className="font-semibold">Add-ons</span>
                        <div className="flex-grow ml-2 border-t border-gray-900"></div>
                    </div>
                )}
                {addonConfig.map((plan) =>
                    plan.quantity > 0 ? (
                        <div key={plan.name} className="mt-2 flex text-sm justify-between">
                            <div className="text-gray-800">{plan.name}</div>

                            <div className="text-gray-700">
                                {plan.quantity > 1 ? (
                                    <div className="flex items-center">
                                        <span>
                                            {plan.quantity} x ${formatNumberUS(plan.price)}
                                        </span>{' '}
                                        =<span className="ml-2">${formatNumberUS(plan.quantity * plan.price)}</span>
                                    </div>
                                ) : (
                                    <div>${formatNumberUS(plan.price)}</div>
                                )}
                            </div>
                        </div>
                    ) : null
                )}

                {(planConfig.length > 0 || addonConfig.length > 0) && (
                    <div>
                        <div className="mt-1 flex-grow border-t border-gray-900"></div>
                        <div className="mt-1 flex justify-between text-l font-bold">
                            <span>Total</span>
                            <span>${formatNumberUS(discountedTotalPrice)}</span>
                        </div>
                    </div>
                )}
            </div>

            <Spacer height={10} />

            {/* Display recurring charges with discount */}
            <div className="px-10 py-5 border rounded-lg border-orange-600 fill-background bg-orange-100">
                <span>
                    The customer will be billed{' '}
                    <span className="font-bold">${formatNumberUS(discountedRecurringPrice)}</span> for this subscription
                    every month on the 6th.
                </span>
            </div>
        </div>
    )
}
