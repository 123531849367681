import { useState } from 'react'
import { TextField, Typography, InputAdornment } from '@mui/material'
import { ModelGuardRail } from '../../api/models/model_guardrail'
import { validateEffectValue } from '../../utils/utility_functions'
import { Loyalty } from '@mui/icons-material'
import FlexColors from '@/utils/colors'
import Spacer from '@/components/common/util/spacer'
import FlexDropdown from '@/components/common/dropdown/dropdown'
import FlexButton from '@/components/common/button/button'

interface DiscountProps {
    onDiscountApplied: (fixed: number, recurring: number) => void
    label: string
    modelGuardRail?: ModelGuardRail[]
    onClose: () => void
}

const DiscountInput = ({ onDiscountApplied, modelGuardRail, onClose }: DiscountProps) => {
    const [discountRecurring, setDiscountRecurring] = useState<number>(0)
    const [errorFixed, setErrorFixed] = useState<boolean>(false)
    const [errorRecurring, setErrorRecurring] = useState<boolean>(false)

    const [discountFixed, setDiscountFixed] = useState<number>(0)

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}
                >
                    <Loyalty htmlColor={FlexColors.primary} />
                    <Spacer width={10} />
                    <Typography fontSize={20} fontWeight={600} fontFamily={'Open Sans'} color={FlexColors.primary}>
                        Apply discount
                    </Typography>
                </div>
            </div>
            <Spacer height={30} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight={600} fontSize={16}>
                    Discount type
                </Typography>
                <FlexDropdown
                    options={['percentage']}
                    placeHolder="percentage"
                    onSelect={(selectedOption) => {
                        console.log('selected option', selectedOption)
                    }}
                />
            </div>
            <Spacer height={20} />
            <Typography fontWeight={600} fontSize={16}>
                Apply on
            </Typography>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography color={'gray'} fontWeight={500} fontSize={14}>
                    Fixed charges
                </Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    error={errorFixed}
                    helperText={errorFixed && 'Discount exceeds the maximum value'}
                    value={discountFixed}
                    onChange={(event) => {
                        let value = event.target.value
                        if (value === '') {
                            value = '0'
                        }

                        const guardRail: ModelGuardRail | undefined = modelGuardRail?.find(
                            (item) => item.effect_on === 'cadence_fixed_price'
                        )

                        if (guardRail) {
                            if (
                                !validateEffectValue(Number.parseInt(value), guardRail.operator, guardRail.effect_value)
                            ) {
                                setErrorFixed(true)
                            } else {
                                setErrorFixed(false)
                            }
                        }
                        setDiscountFixed(Number.parseInt(value))
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </div>
            <Spacer height={20} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography color={'gray'} fontWeight={500} fontSize={14}>
                    Recurring charges
                </Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    value={discountRecurring}
                    error={errorRecurring}
                    helperText={errorRecurring && 'Discount exceeds the maximum value'}
                    onChange={(event) => {
                        let value = event.target.value
                        if (value === '') {
                            value = '0'
                        }

                        const guardRail: ModelGuardRail | undefined = modelGuardRail?.find(
                            (item) => item.effect_on === 'cadence_recurring_price'
                        )

                        if (guardRail) {
                            if (
                                !validateEffectValue(Number.parseInt(value), guardRail.operator, guardRail.effect_value)
                            ) {
                                setErrorRecurring(true)
                            } else {
                                setErrorRecurring(false)
                            }
                        }
                        setDiscountRecurring(Number.parseInt(value))
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',

                    marginTop: '20px',
                }}
            >
                <FlexButton
                    textSize={16}
                    width={100}
                    height={35}
                    radius={10}
                    text="Apply"
                    onClick={() => {
                        if (!errorFixed && !errorRecurring) {
                            onDiscountApplied(discountFixed, discountRecurring)
                            onClose()
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default DiscountInput
