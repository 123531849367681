import React, { useEffect, useRef, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import { Plan } from '@/api/models/model_product_detail'
import { ModelProduct } from '@/api/models/model_product'
import { ProductAPI } from '@/api/req/product_api'
import { ProductDetailAPI } from '@/api/req/product_detail_api'
import { LuMoreVertical } from 'react-icons/lu'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { useNavigate } from 'react-router-dom'
import Chip from '@/components/common/chip/chip'
import { transformTiersToV2 } from '@/utils/utility_functions'
import { CircularProgress, Container } from '@mui/material'
import FlexColors from '@/utils/colors.ts'
import '../addon/index.css'
import PriceCellRenderer from '../addon/PriceCellRendere'
import { ModelTableData } from '../addon/AddOnPage'
import Spacer from '@/components/common/util/spacer'

export interface PriceTierV2 {
    from: number | string
    to: number | string
    price: number
}

function formatDate(timestamp: string) {
    const date = new Date(timestamp)
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}

const PlansPage = () => {
    const gridRef = useRef<AgGridReact>(null)

    const [loading, setLoading] = useState(true)
    const [addOns, setAddOns] = useState<ModelTableData[]>([])
    const [plans, setPlans] = useState<Plan[]>([])
    const [products, setProducts] = useState<ModelProduct[]>()
    const [selectedProduct, setSelectedProduct] = useState<ModelProduct | null | undefined>(null)

    const ActionCellRenderer: React.FC = (props: any) => {
        const navigate = useNavigate()
        return (
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <LuMoreVertical className={'text-xl '} />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem
                        onClick={() => {
                            navigate(`edit?id=${props.data?.id}`, {
                                state: plans.find((plan) => plan.id == props.data?.id),
                            })
                        }}
                    >
                        Edit
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        )
    }
    useEffect(() => {
        ProductAPI.getAll().then((result: ModelProduct[]) => {
            setProducts(result)
            setLoading(true)
            ProductDetailAPI.getAll(result[0].id)
                .then((product) => {
                    setPlans(product.plans)
                    setAddOns(convertToModelAddon(product.plans))
                    setSelectedProduct(result[0])
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }, [])

    const onHandleChange = (id: string) => {
        setLoading(true)
        ProductDetailAPI.getAll(id)
            .then((product) => {
                setAddOns(convertToModelAddon(product.plans))
            })
            .finally(() => {
                setSelectedProduct(products?.find((product) => product?.id === id))
                setLoading(false)
            })
    }

    const convertToModelAddon = (plans: Plan[]): ModelTableData[] => {
        return plans.map((plan) => ({
            id: plan.id,
            price_id: plan.prices?.[0]?.id,
            name: plan.name,
            slug: plan.slug,
            tier_type: plan.prices?.[0]?.tier_type === 'VOLUME' ? 'Tiered' : 'Flat fee',
            status: plan.status,
            price:
                plan.prices?.[0]?.tier_type === 'VOLUME'
                    ? transformTiersToV2(plan.prices?.[0]?.price_tiers || [])
                    : plan.prices?.[0]?.monthly_price || 0,
            updated_at: plan.updated_at,
        }))
    }

    const colDefs = [
        {
            headerName: 'Name',
            field: 'name',
            filter: true,
            sortable: true,
            flex: 5,
        },
        {
            headerName: 'Pricing model',
            field: 'tier_type',
            filter: true,
            sortable: true,
            flex: 2,
        },
        {
            headerName: 'Status',
            field: 'status',
            cellRenderer: (params: any) => (
                <div className="flex justify-center w-full items-center h-full ">
                    <Chip isActive={params.value === 'published'} />
                </div>
            ),
            filter: true,
            sortable: true,
            width: 100,
        },
        {
            headerName: 'Price',
            field: 'price',
            cellRenderer: (params: any) => <PriceCellRenderer params={params} />,
            filter: true,
            sortable: true,
            flex: 3,
        },
        {
            headerName: 'Last updated',
            field: 'updated_at',
            valueGetter: (s: any) => formatDate(s.data.updated_at),
            filter: true,
            sortable: true,
            flex: 2,
        },
        {
            headerName: '',
            field: 'actions',
            cellRenderer: ActionCellRenderer,
            width: 60,
        },
    ]

    const onGridReady = (params: any) => {
        gridRef.current!.api.sizeColumnsToFit()
        // Auto-size the Actions column to fit its content
        params.columnApi.autoSizeColumn('actions')
    }

    return (
        <Container>
            <Spacer height={10} />
            <div className=" min-h-screen w-full">
                <div className="p-4  bg-white card  justify-between items-center mt-8">
                    <h1 className="text-3xl font-bold">Plans</h1>
                    <div className="mt-2 flex gap-4 items-center">
                        <h3 className="font-medium">
                            Select product
                            <span className="text-red-600">*</span>
                        </h3>

                        <Select
                            value={selectedProduct?.name}
                            onValueChange={(selectedOption) => {
                                const selectedProduct = products?.find((product) => product.name === selectedOption)
                                onHandleChange(selectedProduct!.id)
                            }}
                        >
                            <SelectTrigger className="w-[300px]">
                                <SelectValue placeholder="Theme" />
                            </SelectTrigger>
                            <SelectContent>
                                {products?.map((value, index) => (
                                    <SelectItem key={index} value={value.name}>
                                        {value.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className="ag-theme-quartz card mt-4  w-full h-auto p-4 bg-white">
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={colDefs}
                        rowData={addOns}
                        domLayout="autoHeight"
                        loading={loading}
                        loadingOverlayComponent={() => (
                            <CircularProgress size={50} style={{ color: FlexColors.primary }} />
                        )}
                        onGridReady={onGridReady}
                    />
                </div>
            </div>
        </Container>
    )
}

export default PlansPage
