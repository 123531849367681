import { Price } from '@/api/models/model_product_detail'
import { PriceTierV2 } from './PlansPage'
import { transformTiersToV2 } from '@/utils/utility_functions'

export const extractPlan = (prices: Price[], billingPeriod: string) => {
    console.log(billingPeriod)
    const price = prices.find((price) => price.billing_period === billingPeriod)

    let price_data: PriceTierV2[] | number = 0

    if (price) {
        if (price.tier_type === 'VOLUME') {
            price_data = transformTiersToV2(price.price_tiers || [])
        } else {
            price_data = billingPeriod.toUpperCase() === 'MONTHLY' ? price.monthly_price ?? 0 : price.annual_price ?? 0
        }
    }

    return {
        tier_type: (price?.tier_type === 'VOLUME' ? 'Tiered' : 'Flat fee') || 'Flat fee',
        price_id: price?.id || undefined,
        price: price_data ?? 0,
    }
}
