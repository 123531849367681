import DeleteIcon from '@mui/icons-material/Delete'
import { PlanConfig } from '../CustomerSubscription'

interface PlanConfigTableProps {
    planConfig: PlanConfig[]
    onValueChanged: (key: string, value: string) => void
    onDelete?: (key: string) => void
}

export default function PlanConfigTable({ planConfig, onValueChanged, onDelete }: PlanConfigTableProps) {
    return (
        <div className="border rounded-xl pt-2 pb-2 bg-gray-100">
            <table className="min-w-full">
                <thead className="rounded-xl">
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 text-left text-gray-600">Charge</th>
                        <th className="px-4 py-2 text-left text-gray-600">Quantity</th>
                        <th className="px-4 py-2 text-left text-gray-600">Price</th>
                        {onDelete && <th className="px-4 py-2 text-left text-gray-600"></th>}
                    </tr>
                </thead>
                <tbody>
                    {planConfig.map((config, index) => (
                        <tr key={index} className="border-t bg-white items-center align-middle">
                            <td className="px-4 py-3">{config.name}</td>

                            <td className="px-4 py-3">
                                {config.isEditable ? (
                                    <input
                                        type="number"
                                        min="0"
                                        className="w-full p-2 text-sm border border-gray-300 rounded"
                                        value={config.quantity}
                                        onChange={(e) => onValueChanged(config.name, e.target.value)}
                                    />
                                ) : (
                                    <span>{config.quantity}</span>
                                )}
                            </td>

                            {/* Price column */}
                            <td className="px-4 py-3">
                                ${config.price.toFixed(2)} {config.unit ? `/ ${config.unit}` : ''} /{' '}
                                {config.billingPeriod}
                            </td>

                            {/* Delete button */}
                            {onDelete && (
                                <td className="px-4 py-3">
                                    <button onClick={() => onDelete(config.name)} className="text-red-500">
                                        <DeleteIcon />
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
