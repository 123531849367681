import { ModelCustomer } from '@/api/models/model_customer'

interface CustomerDetailProp {
    customer: ModelCustomer
}
export default function CustomerDetail({ customer }: CustomerDetailProp) {
    return (
        <div className="px-5 py-3 border rounded-lg bg-white border-orange-500 shadow-md shadow-gray-300">
            <div className="flex py-2">
                <div className="w-1/4 text-left pr-4 font-semibold text-gray-600">Customer ID</div>
                <div className="w-3/4 text-left text-gray-800">{customer.slug ?? ''}</div>
            </div>
            <div className="flex py-2">
                <div className="w-1/4 text-left pr-4 font-semibold text-gray-600">Name</div>
                <div className="w-3/4 text-left text-gray-800">{customer.name ?? ''}</div>
            </div>
            <div className="flex py-2">
                <div className="w-1/4 text-left pr-4 font-semibold text-gray-600">Email</div>
                <div className="w-3/4 text-left text-gray-800">{customer.email ?? ''}</div>
            </div>
        </div>
    )
}
